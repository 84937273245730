import DocsetMenu from "../../../../source/components/docset-menu";
import { MenuWrapper } from "../../../../source/components/menu";
import WorkoutMenuItem from "../../../../source/components/workout-menu-item";
import { Button } from '@apollo/space-kit/Button';
import { IconRobot1 } from '@apollo/space-kit/icons/IconRobot1';
import { Link } from 'gatsby';
import { colors } from 'gatsby-theme-apollo-core';
import { ExpansionPanel } from 'gatsby-theme-apollo-docs/src/components/expansion-panel';
import { ReactComponent as BadmintonPlayer } from "../../../../source/assets/badminton-player.svg";
import { ReactComponent as Paragliding } from "../../../../source/assets/paragliding.svg";
import { ReactComponent as Weightlift } from "../../../../source/assets/weightlift.svg";
import { ReactComponent as Diving } from "../../../../source/assets/diving.svg";
import { ReactComponent as HeadLight } from "../../../../source/assets/head-light.svg";
import * as React from 'react';
export default {
  DocsetMenu,
  MenuWrapper,
  WorkoutMenuItem,
  Button,
  IconRobot1,
  Link,
  colors,
  ExpansionPanel,
  BadmintonPlayer,
  Paragliding,
  Weightlift,
  Diving,
  HeadLight,
  React
};