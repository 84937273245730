import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`The code blocks below use TypeScript by default.`}</strong>{` You can use the dropdown menu above each code block to switch to JavaScript.`}</p>
      <p parentName="blockquote">{`If you're using JavaScript, use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.js`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.jsx`}</code>{` file extensions wherever `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.ts`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`.tsx`}</code>{` appear.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      